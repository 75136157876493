import React, { useState } from "react"
import { Table } from "reactstrap"
import "./style.css"
import { Images } from "../../utils"

const CustomTable = (props) => {
  const { heading, deleteUser, baneUser, feedsState, setFeedsState ,getUsers ,toggle } = props
  const [expandedRows, setExpandedRows] = useState([])

  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows
    const isRowExpanded = currentExpandedRows.includes(userId)

    let obj = {}
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true)

    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter(id => id !== userId)
      : currentExpandedRows.concat(userId)

    setExpandedRows(newExpandedRows)
  }

  const deleteUserAction = (item) => {
    deleteUser(item?.id)
    onDeleteFilter(item?.id)
    setTimeout(()=>{
      const data = { page: Number(1) ,type: toggle ,search:false }
      getUsers(data)
    },2000)
  }

  const onDeleteFilter = (postId) => {
    const updatedFeeds = [...feedsState];
    const objToUpdate = updatedFeeds.filter(item => item.id !== postId);
    setFeedsState(objToUpdate);
  };

  const baneUserAction = (item) => {
    baneUser(item?.id)
    onFilterFilter(item?.id)
  }

  const onFilterFilter = (postId) => {
    const updatedFeeds = [...feedsState];
    const index = updatedFeeds.findIndex(item => item.id === postId);
    const objToUpdate = updatedFeeds[index];
    objToUpdate.is_active = false
    updatedFeeds[index] = objToUpdate;
    setFeedsState(updatedFeeds);
  };

  return (
    <Table responsive className="table">
      <thead>
        <tr>
          {heading.map((item, i) => (
            <th key={i} colSpan={heading.length - 1 === i ? 2 : 0}>
              <div className="sort-tex-main">
                <img alt="" src={item==="Actions"?" ":Images.sortupdownIcon} />
                <span>{item}</span>
              </div>
            </th>
          ))}
        </tr>
      </thead>

      {feedsState && feedsState.map((item, i) => {
        return (
          <tbody key={i}>
            <tr className={expandedRows.includes(item.id) ? "active-class" : ""}>
              <td onClick={event => handleEpandRow(event, item.id)}>{item?.name}</td>
              <td onClick={event => handleEpandRow(event, item.id)}>{item?.email}</td>
              <td onClick={event => handleEpandRow(event, item.id)}>{item?.user_profile?.state === null ? "" : item?.user_profile?.state + " " + item?.user_profile?.country === null ? "" : item?.user_profile?.country}</td>
              <td onClick={event => handleEpandRow(event, item.id)}>{item?.user_profile?.gender === null ? "" : item?.user_profile?.gender.charAt(0).toUpperCase()+ item?.user_profile?.gender.slice(1) }</td>
              <td onClick={event => handleEpandRow(event, item.id)}>
                <div className="user-type-main">
                  <div
                    className="user-type-before"
                    style={
                      item?.user_type === "Parent"
                      || item?.user_type === "parent"
                      ? { background: "#C67D7B" }
                      :item?.user_type?.toLowerCase() === "professional" ? { background: "#929D8B" } : { display:"none" }
                    }
                  />
                  <span className="user-type">{item?.user_type!==null?item?.user_type.charAt(0).toUpperCase()+ item?.user_type.slice(1):"" }</span>
                </div>
              </td>
              <td
                style={{ zIndex: 9999999 }}>
                <div className="delete-block-ions">
                  <img  onClick={() => deleteUserAction(item)} alt="" src={Images.deleteIcon} />
                  <span>{"delete user"}</span>
                </div>
              </td>
              <td>
                <div className="delete-block-ions">
                  <img onClick={() => baneUserAction(item)} alt="" src={Images.blockIcon} />
                  <span>{item.is_active ? "ban user" : "banned"}</span>
                </div>
              </td>
            </tr>
            {expandedRows.includes(item.id) ? (
              <tr className="active-class">
                <td colSpan="7">
                  <div className="colapsed-row-main">
                    <h6>{item.watched_material.length ? "Educational material watched" : ""}</h6>
                    <ul>
                      {item.watched_material.map((video, i) => (
                        <li key={i}>
                          <span>{video?.video__name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </td>
              </tr>
            ) : <></>}
          </tbody>
        )
      })}
    </Table>
  )
}

export default CustomTable
