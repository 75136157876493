import React, { useState } from "react"
import { Table } from "reactstrap"
import "./style.css"
import { Images } from "../../utils"
import { RiRefund2Line } from "react-icons/ri"
import { MdOutlineCancel } from "react-icons/md"

import toast from "react-hot-toast"

const HistoryTable = props => {
  const {
    heading,
    cancelUserSubscription,
    refundUserPayment,
    feedsState,
    setFeedsState,
    getUsers,
    toggle
  } = props
  const [expandedRows, setExpandedRows] = useState([])

  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows
    const isRowExpanded = currentExpandedRows.includes(userId)

    let obj = {}
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true)

    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter(id => id !== userId)
      : currentExpandedRows.concat(userId)

    setExpandedRows(newExpandedRows)
  }

  const cancelUserAction = item => {
    if (item?.stripe_customer_id) {
      const data = {
        stripe_customer_id: item?.stripe_customer_id
      }
      cancelUserSubscription(data)
      setTimeout(() => {
        const data = { page: Number(1), type: toggle, search: false }
        getUsers(data)
      }, 2000)
    } else {
      toast.error("Strip Account Not Available")
    }
    // onDeleteFilter(item?.id)
  }

  const onDeleteFilter = postId => {
    const updatedFeeds = [...feedsState]
    const objToUpdate = updatedFeeds.filter(item => item.id !== postId)
    setFeedsState(objToUpdate)
  }

  const refundUserAction = item => {
    if (item?.stripe_customer_id) {
      const data = {
        stripe_customer_id: item?.stripe_customer_id
      }
      refundUserPayment(data)
    } else {
      toast.error("Strip Account Not Available")
    }

    // onFilterFilter(item?.id)
  }

  const onFilterFilter = postId => {
    const updatedFeeds = [...feedsState]
    const index = updatedFeeds.findIndex(item => item.id === postId)
    const objToUpdate = updatedFeeds[index]
    objToUpdate.is_active = false
    updatedFeeds[index] = objToUpdate
    setFeedsState(updatedFeeds)
  }

  return (
    <Table responsive className="table">
      <thead>
        <tr>
          {heading.map((item, i) => (
            <th key={i} colSpan={heading.length - 1 === i ? 2 : 0}>
              <div className="sort-tex-main">
                <img
                  alt=""
                  src={item === "Actions" ? " " : Images.sortupdownIcon}
                />
                <span style={{ whiteSpace: "nowrap" }}>{item}</span>
              </div>
            </th>
          ))}
        </tr>
      </thead>

      {feedsState &&
        feedsState.map((item, i) => {
          return (
            <tbody key={i}>
              <tr
                className={expandedRows.includes(item.id) ? "active-class" : ""}
              >
                <td title={item?.plan_id}>{item?.plan_id}</td>
                <td title={item?.plan_title}>{item?.plan_title}</td>
                <td title={item?.username === null ? "" : item?.username}>
                  {item?.username === null ? "" : item?.username}
                </td>
                <td title={item?.email === null ? "" : item?.email}>
                  {item?.email === null ? "" : item?.email}
                </td>
                <td title={item?.user === null ? "" : item?.user}>
                  {item?.user === null ? "" : item?.user}
                </td>
                <td title={item?.user_device === null ? "" : item?.user_device}>
                  {item?.user_device === null ? "" : item?.user_device}
                </td>
                <td
                  title={
                    item?.purchase_date === null ? "" : item?.purchase_date
                  }
                >
                  {item?.purchase_date === null ? "" : item?.purchase_date}
                </td>
                <td title={item?.expire_date === null ? "" : item?.expire_date}>
                  {item?.expire_date === null ? "" : item?.expire_date}
                </td>
                <td
                  title={
                    item?.stripe_customer_id === null
                      ? ""
                      : item?.stripe_customer_id
                  }
                >
                  {item?.stripe_customer_id === null
                    ? ""
                    : item?.stripe_customer_id}
                </td>
                <td title={item?.status === null ? "" : item?.status}>
                  {item?.status === null ? "" : item?.status}
                </td>

                <td style={{ zIndex: 9999999 }}>
                  <div
                    className="delete-block-ions"
                    onClick={() => cancelUserAction(item)}
                  >
                    <MdOutlineCancel style={{ fontSize: "20px" }} />
                    <span>{"Cancel"}</span>
                  </div>
                </td>
                <td style={{ width: "50px" }}>
                  <div
                    className="delete-block-ions"
                    onClick={() => refundUserAction(item)}
                  >
                    <RiRefund2Line style={{ fontSize: "20px" }} />
                    <span>{"Refund"}</span>
                  </div>
                </td>
              </tr>
              {expandedRows.includes(item.id) ? (
                <tr className="active-class">
                  <td colSpan="7">
                    <div className="colapsed-row-main">
                      <h6>
                        {item.watched_material.length
                          ? "Educational material watched"
                          : ""}
                      </h6>
                      <ul>
                        {item.watched_material.map((video, i) => (
                          <li key={i}>
                            <span>{video?.video__name}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </tbody>
          )
        })}
    </Table>
  )
}

export default HistoryTable
