import React, { useState, useEffect } from "react"
import { Button, Col, Input, Label, Row } from "reactstrap"
import NavBar from "../../components/Navbar"
import SearchInput from "../../components/Search"
import moment from "moment/moment"
import "./style.css"
import { Images } from "../../utils"
import { NotificationCard } from "../../components/notificationCard"
import {
  deleteNotificationdata,
  getNotificationsRequest,
  searchNotificationData,
  postNotificationsRequest
} from "../../screenRedux/notificationsRedux"
import { connect } from "react-redux"
import Loader from "react-js-loader"
const Notification = props => {
  const { notifications, requesting } = props

  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [isSubmitted,setIsSubmitted] = useState(false)

  useEffect(() => {
    props.getNotificationsRequest()
  }, [])
  const onSubmit = () => {
    const data = {
      title: title,
      message: message
    }
      props.postNotificationsRequest(data)
      setIsSubmitted(true)
      setTitle('')
      setMessage('')
  }
  return (
    <>
      <NavBar />
      <div className="content">
        <div className="contentWraper">
          <div className="container">
            <Row>
              <Col>
                <h4>Notifications</h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <SearchInput
                  onChange={e => props.searchNotificationData(e.target.value)}
                  placeHolder="Search"
                />
              </Col>
            </Row>
              <div className="feedback-card-body">
                <Row>
                {requesting ? (
              <div className="loader-style">
                <Loader
                  type="spinner-cub"
                  bgColor={"#C67D7B"}
                  color={"#C67D7B"}
                  size={100}
                />
              </div>
            ) : !requesting && notifications && notifications?.length === 0 ? <Col><div className="data-not-found">No data found</div></Col>  : (
                  <Col md="5" className="mt-3">
                    <div className="notification-card-wraper">
                      {notifications &&
                        notifications?.map((data, i) => (
                          <NotificationCard
                            key={i}
                            id={data.id}
                            title={data.title}
                            dateTime={moment(data.created).format("DD MMM, YYYY")}
                            notificationContent={data.message}
                            duration={moment(data.created).format("hh:mm")}
                            onHandelDelete={e =>
                              props.deleteNotificationdata({ id: data.id })
                            }
                            onHandelResend={e => { 
                              const resendData = {
                              id: data.id,
                              title: data.title,
                              message: data.message,
                            }
                            props.postNotificationsRequest(resendData)}} 
                          />
                        ))}
                    </div>
                  </Col>
               )} 

               {requesting? ""
                   :<Col md="7" className="mt-3">
                   <div className="d-flex align-items-center justify-content-between notification-left-top-card">
                     <Input className="title-input" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                   </div>
                   <div className="mt-3 bottom-notification-card">
                     <textarea
                       className="notification-textarea"
                       placeholder="Notification Content"
                       value={message}
                       onChange={(e) => setMessage(e.target.value)}
                     />
                   </div>
                   <div className="submitbtn-main" onClick={(title !=="" && message !=="") ? onSubmit : null}>
                     <p className="submit-btn">Submit</p>
                   </div>
                 </Col>}
                </Row>
              </div>  
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  notifications: state.notificationsReducer.notifications,
  requesting: state.notificationsReducer.requesting
})

const mapDispatchToProps = dispatch => ({
  getNotificationsRequest: () => dispatch(getNotificationsRequest()),
  postNotificationsRequest: (data) => dispatch(postNotificationsRequest(data)),
  deleteNotificationdata: data => dispatch(deleteNotificationdata(data)),
  searchNotificationData: data => dispatch(searchNotificationData(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
