import React from "react"
import { Col, Row } from "reactstrap"
import NavBar from "../../components/Navbar"

const Analytics = () => {
  return (
    <>
      <NavBar />
      <div className="content">
        <div className="contentWraper">
          <div className="container">
            <Row>
              <Col>
                <h4>Analytics</h4>
              </Col>
            </Row>
        </div>
        </div>
    </div>

    </>
  )
}

export default Analytics
