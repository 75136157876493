import { toast } from "react-hot-toast"
import { all, call, put, takeLatest } from "redux-saga/effects"

// config
import { BASE_URL } from "../config/app"

// utils
import XHR from "../utils/XHR"

// type
export const GET_FEEDBACK_REQUEST = "eddifi/FEEDBACK/GET_FEEDBACK_REQUEST"
export const GET_FEEDBACK_SUCCESS = "eddifi/FEEDBACK/GET_FEEDBACK_SUCCESS"
export const GET_FEEDBACK_FAILURE = "eddifi/FEEDBACK/GET_FEEDBACK_FAILURE"
export const SEARCH_FEEDBACK_REQUEST = "eddifi/FEEDBACK/SEARCH_FEEDBACK_REQUEST"

export const POST_FEEDBACK_REQUEST = "eddifi/FEEDBACK/POST_FEEDBACK_REQUEST"
export const POST_FEEDBACK_SUCCESS = "eddifi/FEEDBACK/POST_FEEDBACK_SUCCESS"
export const POST_FEEDBACK_FAILURE = "eddifi/FEEDBACK/POST_FEEDBACK_FAILURE"

// action
export const getFeedbackRequest = data => ({
  type: GET_FEEDBACK_REQUEST,
  data
})

export const getFeedbackSuccess = data => ({
  type: GET_FEEDBACK_SUCCESS,
  data
})

export const getFeedbackFailure = error => ({
  type: GET_FEEDBACK_FAILURE,
  error
})

export const searchFeedbackValue = data => ({
  type: SEARCH_FEEDBACK_REQUEST,
  data
})

export const postFeedbackRequest = data => ({
  type: POST_FEEDBACK_REQUEST,
  data
})

export const postFeedbackSuccess = data => ({
  type: POST_FEEDBACK_SUCCESS,
  data
})

export const postFeedbackFailure = error => ({
  type: POST_FEEDBACK_FAILURE,
  error
})

// reducer

const initialState = {
  requesting: false,
  feedbackData: false,
  feedbackDataError: false,
  postfeedbackError: false,
  postFeedbackData: false
}

export const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FEEDBACK_REQUEST:
    case SEARCH_FEEDBACK_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        requesting: false,
        feedbackData: action.data
      }

    case GET_FEEDBACK_FAILURE:
      return {
        ...state,
        requesting: false,
        feedbackDataError: action.error
      }

    case POST_FEEDBACK_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case POST_FEEDBACK_SUCCESS:
      return {
        ...state,
        requesting: false,
        postFeedbackData: action.data
      }

    case POST_FEEDBACK_FAILURE:
      return {
        ...state,
        requesting: false,
        postfeedbackError: action.error
      }

    default:
      return state
  }
}

// sagas

async function getFeedbackDetailsAPI() {
  const URL = `${BASE_URL}/api/v1/feedback/`
  const token = localStorage.getItem("accessToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getFeedbackDetails({}) {
  try {
    const response = yield call(getFeedbackDetailsAPI)
    yield put(getFeedbackSuccess(response.data))
  } catch (e) {
    console.log("feedback error: ", e)
    const { response } = e
    yield put(getFeedbackFailure(response.data))
  }
}

async function searchFeedbackAPI(data) {
  const URL = `${BASE_URL}/api/v1/feedback/?search=${data}`
  const token = localStorage.getItem("accessToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* searchFeedback({ data }) {
  try {
    const response = yield call(searchFeedbackAPI, data)
    yield put(getFeedbackSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getFeedbackFailure(response.data))
  }
}

async function postFeedbackAPI(data) {
  const URL = `${BASE_URL}/api/v1/feedback_reply/`
  const token = localStorage.getItem("accessToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postFeedback({ data }) {
  try {
    const response = yield call(postFeedbackAPI, data)
    yield put(postFeedbackSuccess(response.data))
    toast.success("Response submitted successfully", { position: 'top-right' })
  } catch (e) {
    const { response } = e
    yield put(postFeedbackFailure(response.data))
    toast.error("Please Try Again")
  }
}

export default all([
  takeLatest(GET_FEEDBACK_REQUEST, getFeedbackDetails),
  takeLatest(SEARCH_FEEDBACK_REQUEST, searchFeedback),
  takeLatest(POST_FEEDBACK_REQUEST, postFeedback)
])
