import React, { useState, useEffect } from "react"
import { Button, Col, Row, ButtonGroup } from "reactstrap"
import NavBar from "../../components/Navbar"
import "./style.css"
import SearchInput from "../../components/Search"
import HistoryTable from "../../components/CustomTable/HistoryTable"
import { connect } from "react-redux"
import {
  getHistoryRequest,
  cancelSubscriptionRequest,
  refundPaymentRequest
} from "../../screenRedux/historyRedux"
import Pagination from "../../components/Pagination"
import Loader from "react-js-loader"

const History = props => {
  const { requesting, historyData } = props
  const [toggle, setToggle] = useState("all-users")
  const [pageNumber, setPageNumber] = useState(1)
  const [feedsState, setFeedsState] = useState([])
  const [value, setValue] = useState("")

  useEffect(() => {
    const data = { page: Number(pageNumber), type: toggle, search: false }
    props.getHistoryRequest(data)
  }, [pageNumber, toggle])

  useEffect(() => {
    historyData &&
      historyData?.results?.length &&
      setFeedsState(historyData?.results)
  }, [historyData])

  const heading = [
    "Plan ID",
    "Plan Title",
    "Name",
    "Email",
    "Users",
    "Device",
    "Start Date",
    "End Date",
    "User ID",
    "Status",
    "Actions"
  ]

  const selectPagination = val => {
    setPageNumber(val)
    const data = { page: val, type: toggle, search: false }
    props.getHistoryRequest(data)
  }

  const onChange = e => {
    setValue(e.target.value)
    const data = {
      page: Number(pageNumber),
      type: toggle,
      search: e.target.value
    }
    props.getHistoryRequest(data)
  }

  return (
    <>
      <NavBar />
      <div className="content">
        <div className="contentWraper">
          <div className="screen-container">
            <Row>
              <Col>
                <h4 className="user-heading">Payment History</h4>
              </Col>
            </Row>
            <Row>
              <Col md="12" lg="12" xl="12" className="mt-4 mt-md-0">
                <SearchInput
                  onChange={onChange}
                  placeHolder="Search"
                  value={value}
                />
              </Col>
            </Row>

            <Row>
              {requesting ? (
                <div className="loader-style">
                  <Loader
                    type="spinner-cub"
                    bgColor={"#C67D7B"}
                    color={"#C67D7B"}
                    size={100}
                  />
                </div>
              ) : !requesting &&
                historyData &&
                historyData?.results.length === 0 ? (
                <Col>
                  <div className="data-not-found">No data found</div>
                </Col>
              ) : (
                <Col>
                  <HistoryTable
                    users={historyData}
                    heading={heading}
                    cancelUserSubscription={props.cancelSubscriptionRequest}
                    refundUserPayment={props.refundPaymentRequest}
                    feedsState={feedsState}
                    setFeedsState={setFeedsState}
                    getUsers={props.getHistoryRequest}
                    toggle={toggle}
                    pageNumber={pageNumber}
                  />
                  <Pagination
                    totalPages={historyData?.count}
                    pageNumber={pageNumber}
                    onPressPage={selectPagination}
                  />
                </Col>
              )}
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  requesting: state.historyReducer.requesting,
  historyData: state.historyReducer.historyData
})

const mapDispatchToProps = dispatch => ({
  getHistoryRequest: data => dispatch(getHistoryRequest(data)),
  cancelSubscriptionRequest: data => dispatch(cancelSubscriptionRequest(data)),
  refundPaymentRequest: data => dispatch(refundPaymentRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(History)
