const validator = {
  name: {
    regEx: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
    error: "Only alphabetic letters are allowed with spaces in between."
  },
  email: {
    // eslint-disable-next-line
    regEx:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    error: "Invalid email address."
  },
  phone: {
    regEx: /^\d+$/,
    error: "Enter a valid phone number without a + sign."
  },
  password: {
    regEx: /(?=^.{8,16}$)(?=.*\d)(?=.*\W+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
    error:
      "Password required minimum length of 8 (with an uppercase, a lowercase, a number and a special character atleast)"
  },
  numeric: {
    regEx: /^\d+$/,
    error: "Only numeric digits allowed."
  }
}

export default validator
