import React from "react"
import styled from "styled-components"

export const FeedbackCard = props => {

  return (
    <StyledCard style={props.style}>
      <div className="card-main">
        <div className="card-top">
          <div className="user-name-email">
            <h5>{props.name}</h5>
            <p style={{ marginLeft: -8 }}>{props.email}</p>
          </div>
          <p className="professional">{props.type}</p>
        </div>
        <div className="card-main-body">
          <h5 style={{ marginLeft: -8 }}>{props.messageheading}</h5>
          <p style={{ marginLeft: -8 }}>{props.message}</p>
          <div className="d-flex justify-content-end">
            <span onClick={props.onHandelReply} className="reply">Reply</span>
          </div>
        </div>
      </div>
    </StyledCard>
  )
}

const StyledCard = styled.div`
  padding: 16px 16px 16px 22px;
  :first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  :last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .card-top {
    display: flex;
    justify-content: space-between;

    .user-name-email {
      position:relative;
      h5 {
        font-weight: 600;
        color: #c67d7b;
        margin-bottom: 8px;
        font-size: 14px;
        &:before {
          content: "";
          height: 6px;
          width: 6px;
          max-width: 6px;
          min-width: 6px;
          background: #C67D7B;
          border-radius: 100px;
          position:absolute;
          left:-9px;
          top:5px;
        }
      }
      p {
        color: #808080;
        font-size: 14px;
      }
    }
    .professional {
      font-size: 14px;
      color: #808080;
    }
  }
  .card-main-body {
    h5 {
      font-size: 14px;
      font-weight: 600;
      margin-top: 8px !important;
    }
    p {
      font-size: 14px;
      color: #808080;
      // width: 98%;
      // height: 66px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
  .reply {
    font-size: 14px;
    color: #808080;
    cursor:pointer;
  }
`
