import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

//Reducers
import { loginReducer } from "../screenRedux/loginRedux"
import { notificationsReducer } from "../screenRedux/notificationsRedux"
import { usersReducer } from "../screenRedux/usersRedux"
import { feedbackReducer } from "../screenRedux/feedbackRedux"
import { historyReducer } from "../screenRedux/historyRedux"
const signInPersistConfig = {
  key: "login",
  storage,
  timeout: null
}

export const combinedReducers = history => ({
  login: persistReducer(signInPersistConfig, loginReducer),
  usersReducer,
  historyReducer,
  notificationsReducer,
  feedbackReducer
})
