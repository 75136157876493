import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from "react-hot-toast"

// config
import { BASE_URL } from '../config/app';

// utils
import XHR from '../utils/XHR';

// type
export const GET_NOTIFICATIONS_REQUEST = 'eddifi/NOTIFICATION/GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'eddifi/NOTIFICATION/GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'eddifi/NOTIFICATION/GET_NOTIFICATIONS_FAILURE';

export const DELETE_NOTIFICATION_REQUEST = 'eddifi/NOTIFICATION/DELETE_NOTIFICATION_REQUEST';
export const SEARCH_NOTIFICATION_REQUEST = 'eddifi/NOTIFICATION/SEARCH_NOTIFICATION_REQUEST';

export const POST_NOTIFICATIONS_REQUEST = 'eddifi/NOTIFICATION/POST_NOTIFICATIONS_REQUEST';

// action
export const getNotificationsRequest = (data) => ({
  type: GET_NOTIFICATIONS_REQUEST,
  data,
});

export const getNotificationsSuccess = (data) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  data,
});

export const getNotificationsFailure = (error) => ({
  type: GET_NOTIFICATIONS_FAILURE,
  error,
});

export const postNotificationsRequest = (data) => ({
  type: POST_NOTIFICATIONS_REQUEST,
  data,
});

export const deleteNotificationdata = (data) => ({
  type: DELETE_NOTIFICATION_REQUEST,
  data
});
export const searchNotificationData = (data) => ({
  type: SEARCH_NOTIFICATION_REQUEST,
  data
});

// reducer

const initialState = {
  requesting: false,
  notifications: false,
  getNotificationsError: false
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
    case SEARCH_NOTIFICATION_REQUEST:
      return {
        ...state,
        requesting: true,
      };

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        requesting: false,
        notifications: action.data,
      };

    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        requesting: false,
        getNotificationsError: action.error,
      };
      case POST_NOTIFICATIONS_REQUEST:
        return {
          ...state,
          requesting: true,
        };

    default:
      return state;
  }
};

// sagas
async function getNotificationsDetailsAPI() {
  const URL = `${BASE_URL}modules/firebase-push-notifications/admin_notification/`;
  const token =  localStorage.getItem('accessToken')
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* getNotificationsDetails({ }) {
  try {
    const response = yield call(getNotificationsDetailsAPI);
    yield put(getNotificationsSuccess(response.data));

  } catch (e) {
    console.log('notification error: ', e);
    const { response } = e;
    yield put(getNotificationsFailure(response.data));
  }
}


async function postNotificationsDetailsAPI(data) {
  const URL = `${BASE_URL}modules/firebase-push-notifications/notification/`;
  const token = localStorage.getItem('accessToken')
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`
    },
    method: 'POST',
    data
  };
  return XHR(URL, options);
}

function* postNotificationsDetails({ data }) {
  try {
    const response = yield call(postNotificationsDetailsAPI, data);
    toast.success("Notification sent successfully")
    yield put(getNotificationsRequest());
  } catch (e) {
    console.log('response err: ', e && e.response);
    toast.error("Notifications sending failed")
  }
}


async function deleteNotificationAPI(data) {
  const URL = `${BASE_URL}modules/firebase-push-notifications/admin_notification/${data.id}/`;
  const token = localStorage.getItem('accessToken')
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`
    },
    method: 'DELETE',
  };
  return XHR(URL, options);
}

function* deleteNotification({ data }) {
  try {
    yield call(deleteNotificationAPI, data);
    yield put(getNotificationsRequest(1));
    toast.success("Notification deleted successfully")

  } catch (e) {
    console.log('notification error: ', e);
  }
}

async function searchNotificationAPI(data) {
  const URL = `${BASE_URL}modules/firebase-push-notifications/admin_notification/?search=${data}`;
  const token = localStorage.getItem('accessToken')
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`
    },
    method: 'GET',
  };
  return XHR(URL, options);
}



function* searchNotification({ data }) {
  try {
    const response = yield call(searchNotificationAPI, data);
    yield put(getNotificationsSuccess(response.data));
  } catch (e) {
    const { response } = e;
    yield put(getNotificationsFailure(response.data));
  }
}




export default all([
  takeLatest(POST_NOTIFICATIONS_REQUEST, postNotificationsDetails),
  takeLatest(GET_NOTIFICATIONS_REQUEST, getNotificationsDetails),
  takeLatest(DELETE_NOTIFICATION_REQUEST, deleteNotification),
  takeLatest(SEARCH_NOTIFICATION_REQUEST, searchNotification),
]);
