import React, { useState } from "react"
import {
  Button,
  Input,
  Container,
  Col,
  Row,
  Form,
  FormGroup
} from "reactstrap"
import { connect } from 'react-redux'
import './style.css';
import { loginRequest } from "../../screenRedux/loginRedux";
import validator from "../../utils/validation";
import useForm from "../../utils/useForm";
import { keyValueMapping } from "../../utils/functions";
import Images from "../../utils/Images";


const Login = (props) => {

  const stateSchema = {
    username: {
      value: "",
      error: ""
    },
    password: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    username: {
      required: true,
      validator: validator.email
    },
    password: {
      required: true
    }
  }

  const { state, handleOnChange, disable, handleReset } = useForm(
    stateSchema,
    validationStateSchema
  )

  const [viewPass, setViewPass] = useState(true)

  const onLogin = () => {
    const data = {
      "username": state.username.value,
      "password": state.password.value
    }
    props.loginRequest(data)
  }

  return (
    <div className="login-page">
      <Container className="container-style">
        <h1 className="heading-style">Welcome to admin dashboard</h1>
        <Form>
          <div>
            <Input
              type="email"
              placeholder="Email Address"
              className="email-input"
              value={state.username.value}
              style={{ marginBottom: state.username.error ? 0 : 26 }}
              onChange={e => handleOnChange("username", e.target.value)}
            />
            {state.username.error && (
              <div className="my-1">
                <p className="mb-0" style={{ color: 'red', fontSize: 12 }}>{state.username.error}</p>
              </div>
            )}
          </div>
          <div style={{ position: 'relative' }}>
            <Input
              type={viewPass ? 'password' : 'text'}
              placeholder="Password"
              className="email-input"
              value={state.password.value}
              maxLength={30}
              style={{ marginBottom: state.password.error ? 0 : 34 }}
              onChange={e => handleOnChange("password", e.target.value)}
            />
            {state.password.error && (
              <div className="my-2">
                <p className="mb-0" style={{ color: 'red', fontSize: 12 }}>{state.password.error}</p>
              </div>
            )}
            <div className="eye-icon" onClick={() => setViewPass(!viewPass)}>
              <img src={Images.logoImage} />
            </div>
          </div>
          <Button
            className="button-style"
            style={{ marginTop: state.password.error ? 0 : 26 }}
            onClick={onLogin}
            disabled={disable}
          >Log in</Button>
        </Form>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = (dispatch) => ({
  loginRequest: (data) => dispatch(loginRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
