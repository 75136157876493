import React, { useEffect, useRef, useState } from "react"

import { Col, Input, Label, Row } from "reactstrap"
import { FeedbackCard } from "../../components/feedbackCard"
import NavBar from "../../components/Navbar"
import SearchInput from "../../components/Search"
import "./style.css"
import { Images } from "../../utils"
import { connect } from "react-redux"
import Loader from "react-js-loader"
import {
  getFeedbackRequest,
  postFeedbackRequest,
  searchFeedbackValue
} from "../../screenRedux/feedbackRedux"
import useForm from "../../utils/useForm"
import getStoredState from "redux-persist/es/getStoredState"
const Feedback = props => {
  const { feedbackData, requesting } = props
  const [messageReply, setMessageReply] = useState(false)
  const [togglesmsIcon, setTogglesmsIcon] = useState(false)
  const [replyClick, setReplyClick] = useState(false)
  const [itemData, setItemData] = useState(
    feedbackData && feedbackData?.length && feedbackData?.[0]
  )
  const inputRef = useRef()
  const user = JSON.parse(localStorage.getItem("user"))

  const stateSchema = {
    sendNotificaiton: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    sendNotificaiton: {
      required: true
    }
  }
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  useEffect(() => {
    props.getFeedback()
    setTogglesmsIcon(false)
  }, [])

  useEffect(() => {
    setItemData(feedbackData && feedbackData?.length && feedbackData?.[0])
    setMessageReply(0)
    setTogglesmsIcon(true)
    setReplyClick(true)
  }, [feedbackData])
  const submit = () => {
    const data = {
      name: itemData.subject,
      message: state.sendNotificaiton.value,
      subject: itemData.subject,
      email: itemData?.email,
      feedback: itemData?.id
    }
    if (state.sendNotificaiton.value !== "") {
      props.postFeedbackRequest(data)
      setState(stateSchema)
    }
  }
  const reply = (item, index) => {
    setMessageReply(index)
    setItemData(item)
    setTogglesmsIcon(true)
    inputRef.current.focus()
    setReplyClick(true)
  }

  return (
    <>
      <NavBar />
      <div className="content">
        <div className="contentWraper">
          <div className="container">
            <Row>
              <Col>
                <h4>Feedback</h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <SearchInput
                  onChange={e => props.searchFeedbackValue(e.target.value)}
                  placeHolder="Search"
                />
              </Col>
            </Row>
            {requesting ? (
              <div className="loader-style">
                <Loader
                  type="spinner-cub"
                  bgColor={"#C67D7B"}
                  color={"#C67D7B"}
                  size={100}
                />
              </div>
            ) : !requesting && feedbackData && feedbackData?.length === 0 ? (
              <div className="data-not-found">No data found</div>
            ) : (
              <div className="feedback-card-body">
                <Row>
                  <Col md="5" className="mt-3">
                    <div className="card-wraper">
                      {feedbackData &&
                        feedbackData?.map((data, i) => (
                          <FeedbackCard
                            style={{
                              background:
                                messageReply === i ? " #F6EEED" : "#ffff"
                            }}
                            key={i}
                            name={data.name}
                            email={data.email}
                            type={data.user_type}
                            messageheading={data.subject}
                            message={data.message}
                            onHandelReply={() => reply(data, i)}
                          />
                        ))}
                    </div>
                  </Col>
                  <Col md="7" className="mt-3">
                    <div className="topCard-left-main">
                      <div className="d-flex align-items-center justify-content-between top-card-left">
                        <div>
                          <div>
                            <Label>To:</Label> <span>{itemData.name}</span>
                          </div>
                          <div>
                            <Label>Subject:</Label>{itemData && itemData?.id ? <span>Re:</span> : null}<span>{itemData.subject}</span>
                          </div>
                          <div>
                            <Label>From:</Label> <span>{user && user?.user_profile?.name}</span>
                          </div>
                        </div>
                        {togglesmsIcon &&
                          state.sendNotificaiton.value !== "" ? (
                          <img
                            className="messageIcon"
                            onClick={submit}
                            src={Images.sendIcon}
                            height={38}
                            width={38}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="mt-3 bottom-card-left">
                        <div className="msg-input">
                          <input
                            placeholder="Type message here"
                            value={state.sendNotificaiton.value}
                            onChange={e =>
                              handleOnChange("sendNotificaiton", e.target.value)
                            }
                            ref={inputRef}
                          />
                        </div>
                        <div className="message">
                          <span>Message</span>
                        </div>
                        <div className="message-details">
                          <p className="message-text">{itemData.message}</p>
                        </div>
                        <div className="replies-main">
                          <div className="replies">
                            {replyClick &&
                              itemData?.reply?.slice().reverse().map((reply, i) => (
                                reply.message ? (
                                  <div key={i} className="message-details-right">
                                    <p className="message-text-right">
                                      {reply.message}
                                    </p>
                                  </div>
                                ) : (
                                  <></>
                                )
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  feedbackData: state.feedbackReducer.feedbackData,
  requesting: state.feedbackReducer.requesting
})

const mapDispatchToProps = dispatch => ({
  getFeedback: () => dispatch(getFeedbackRequest()),
  searchFeedbackValue: data => dispatch(searchFeedbackValue(data)),
  postFeedbackRequest: data => dispatch(postFeedbackRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
