import { all, call, put, takeLatest } from 'redux-saga/effects';

// config
import { BASE_URL } from '../config/app';

// utils
import XHR from '../utils/XHR';

import toast, { Toaster } from "react-hot-toast";

// type
export const GET_USERS_REQUEST = 'eddifi/USERS/GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'eddifi/USERS/GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'eddifi/USERS/GET_USERS_FAILURE';

export const DELETE_USER_REQUEST = 'eddifi/USERS/DELETE_USER_REQUEST';

export const BANE_USER_REQUEST = 'eddifi/USERS/BANE_USER_REQUEST';

// action
export const getUserRequest = (data) => ({
  type: GET_USERS_REQUEST,
  data,
});

export const getUserSuccess = (data) => ({
  type: GET_USERS_SUCCESS,
  data,
});

export const getUserFailure = () => ({
  type: GET_USERS_FAILURE
});

export const deleteUserRequest = (data) => ({
  type: DELETE_USER_REQUEST,
  data,
});

export const baneUserRequest = (data) => ({
  type: BANE_USER_REQUEST,
  data,
});

// reducer

const initialState = {
  requesting: false,
  users: false,
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        requesting: true,
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        requesting: false,
        users: action.data,
      };

    case GET_USERS_FAILURE:
      return {
        ...state,
        requesting: false
      };

    default:
      return state;
  }
};

// sagas

async function getUsersDetailsAPI(data) {
  let URL = ""
  if (data.search) {
    if (data.type == 'all-users') {
      const url = `${BASE_URL}api/v1/all_users/?page=${data.page}&search=${data.search}`;
      URL = url
    } else {
      const url = `${BASE_URL}api/v1/all_users/?page=${data.page}&user_type=${data.type}&search=${data.search}`;
      URL = url
    }
  } else {
    if (data.type == 'all-users') {
      const url = `${BASE_URL}api/v1/all_users/?page=${data.page}`;
      URL = url
    } else {
      const url = `${BASE_URL}api/v1/all_users/?page=${data.page}&user_type=${data.type}`;
      URL = url
    }
  }
  const token = localStorage.getItem('accessToken')
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`
    },
    method: 'GET',
  };
  return XHR(URL, options);
}

function* getUsersDetails({ data }) {
  try {
    const response = yield call(getUsersDetailsAPI, data);
    yield put(getUserSuccess(response.data));
  } catch (e) {
    yield put(getUserFailure());
  }
}

async function deleteUserFunctionAPI(data) {
  const URL = `${BASE_URL}api/v1/delete_user/${data}/`;
  const token = localStorage.getItem('accessToken')
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`
    },
    method: 'DELETE',
  };
  return XHR(URL, options);
}

function* deleteUserFunction({ data }) {
  try {
    yield call(deleteUserFunctionAPI, data);
    toast.success("User deleted successfully")
  } catch (e) {
    console.log('e', e);
    toast.error("Failed to delete user")
  }
}

async function baneUserFunctionAPI(data) {
  const URL = `${BASE_URL}api/v1/ban_user/${data}/`;
  const token = localStorage.getItem('accessToken')
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`
    },
    method: 'DELETE',
  };
  return XHR(URL, options);
}

function* baneUserFunction({ data }) {
  try {
    yield call(baneUserFunctionAPI, data);
  } catch (e) {
    console.log('e: ', e);
  }
}

export default all([
  takeLatest(GET_USERS_REQUEST, getUsersDetails),
  takeLatest(DELETE_USER_REQUEST, deleteUserFunction),
  takeLatest(BANE_USER_REQUEST, baneUserFunction),
]);
