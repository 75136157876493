import { toast } from "react-hot-toast"

export const getState = state => {
  const keys = Object.keys(state)
  const values = Object.values(state)
  let data = {}
  keys.forEach((item, i) => {
    data = {
      ...data,
      [item]: values[i].value
    }
  })
  return data
}

export const characterEllipsis = (string, characters = 2) => {
  if (string.length > characters * 2) {
    return (
      string.substr(0, characters) +
      "***" +
      string.substr(string.length - characters, string.length)
    )
  }
  return string
}

export const nonAdminErrorMessage = (message) => {
  if (message === 'Your are not a admin') {
    toast.error('No data available for non admin user')
  } else {
    toast.error('Request failed')
  }
}

export const keyValueMapping = (state) => {
  const keys = Object.keys(state)
  const values = Object.values(state)
  let data = {}
  keys.forEach((item, i) => { data[item] = values[i].value })
  return data
}

export const sliceIntoChunks = (number, currentNumber, chunkSize = 5) => {
  const array = Array.from(Array(number).keys());
  const arr = array?.slice(1);
  const res = [];
  for (let i = 0; i < arr?.length; i += chunkSize) {
    const chunk = arr?.slice(i, i + chunkSize);
    res.push(chunk);
  }
  let result = [];
  res.forEach(element => {
    if (element.includes(currentNumber)) {
      result = element;
    }
  });
  return result;
};
