import { Route, Switch, } from 'react-router-dom';
import { routes } from '../routes';

const Auth = () => {

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  return (
    <Switch>{getRoutes(routes)}</Switch>
  )
}
export default Auth;