import React from 'react'
import ReactDOM from 'react-dom'
import { Switch, Router, Redirect } from 'react-router-dom'
import { store, history } from './Redux/store'
import { Provider } from 'react-redux'

import RouteGuard from './RouterGuard'
import AuthLayout from '../src/layouts/Auth'
import AdminLayout from '../src/layouts/Admin'

import { Toaster } from "react-hot-toast";

import './index.css'
import 'bootstrap/dist/css/bootstrap.css';

const accessToken = localStorage.getItem('accessToken')

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Toaster
        containerStyle={{
          top: 20,
          left: 20,
          bottom: 20,
          right: 20,
        }}
      />
      <Router history={history}>
        <Switch>
          <RouteGuard
            path='/auth'
            component={props => <AuthLayout {...props} />}
          />
          <RouteGuard
            path='/admin'
            component={props => <AdminLayout {...props} />}
            isProtected
          />
          {accessToken ? (
            <Redirect to="/admin/users" />
          ) : (
            <Redirect to="/auth/login" />
          )}
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
