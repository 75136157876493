import { all, call, put, takeLatest } from 'redux-saga/effects';
import localStorage from 'redux-persist/es/storage';
import toast, { Toaster } from "react-hot-toast";
import { push } from "connected-react-router"
// config
import { BASE_URL } from '../config/app';

// utils
import XHR from '../utils/XHR';

// type

export const LOGIN_REQUEST = 'eddifi/Login/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'eddifi/Login/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'eddifi/Login/LOGIN_FAILURE';

export const ACCESS_TOKEN = 'eddifi/Login/ACCESS_TOKEN';

// action

export const loginRequest = (data) => ({
  type: LOGIN_REQUEST,
  data,
});

export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  data,
});

export const accessTokenSuccess = (data) => ({
  type: ACCESS_TOKEN,
  data,
});

export const loginFailure = () => ({
  type: LOGIN_FAILURE
});

// reducer

const initialState = {
  requesting: false,
  user: false,
  authToken: false
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        requesting: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        requesting: false,
        user: action.data,
      };

    case ACCESS_TOKEN:
      return {
        ...state,
        requesting: false,
        authToken: action.data
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        requesting: false
      };

    default:
      return state;
  }
};

// sagas

async function loginFunctionAPI(data) {
  const URL = `${BASE_URL}api/v1/login/`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    data
  };
  return XHR(URL, options);
}

function* loginFunction({ data }) {
  try {
    const response = yield call(loginFunctionAPI, data);
    if (response.data && response?.data?.user?.is_superuser) {
      localStorage.setItem('accessToken', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      yield put(loginSuccess(response.data));
      yield put(accessTokenSuccess(response.data.token));
      toast.success("Login Successfully")
      yield put(push({ pathname: "/admin/users" }))
    } else {
      let message = 'Failed to login, you are not an admin, please try again';
      toast.error(message)
    }
  } catch (e) {
    let message = 'Failed to login, please try again';
    if (e?.response) {
      message = e?.response?.data?.email || e?.response?.data?.password || e?.response?.data?.non_field_errors[0]
    }
    toast.error(message)
    yield put(loginFailure());
  }
}

export default all([takeLatest(LOGIN_REQUEST, loginFunction)]);
