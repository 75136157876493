import Login from "./screens/Login"
import Users from "./screens/Users"
import Feedback from "./screens/Feedback"
import Notification from "./screens/Notification"
import Analytics from "./screens/Analytics"
import History from "./screens/History"

export const routes = [
  {
    path: "/login",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/users",
    component: Users,
    layout: "/admin"
  },
  {
    path: "/notifications",
    component: Notification,
    layout: "/admin"
  },
  {
    path: "/feedback",
    component: Feedback,
    layout: "/admin"
  },
  {
    path: "/analytics",
    component: Analytics,
    layout: "/admin"
  },
  {
    path: "/history",
    component: History,
    layout: "/admin"
  }
]
