import React from "react"
import { Input } from "reactstrap"
import styled from "styled-components"
import { Images } from '../../utils'

const SearchInput = props => {
  return (
    <StyledSearch>
      <div className="user-main-search">
        <img src={Images.searchIcon} />
        <Input
          onChange={props.onChange}
          value={props.value}
          placeholder={props.placeHolder}
        />
      </div>
    </StyledSearch>
  )
}

const StyledSearch = styled.div`
  .user-main-search {
    border-radius: 4px;
    border: 2px solid #e2bebd;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20;
    padding: 0 15px;
    background-color: white;
  }
  .user-main-search img {
    height: 18px;
    width: 18px;
  }
  .user-main-search input {
    border: none !important;
    outline: transparent;
    font-size: 12px !important;
    height: 44px !important;
    padding: 13px !important;
    color: #808080;
  }
  .user-main-search input:focus {
    border-color: transparent !important;
    box-shadow: none !important;
    color: #808080;
  }
`
export default SearchInput