import React from "react"
import styled from "styled-components"

export const NotificationCard = props => {
  return (
    <StyledCard id={props.id}>
      <div id={props.id} className="notification-card-main">
        <div>
          <span className="date-time">{props.dateTime}</span>
        </div>
        <div className="card-top">
          <div className="title">
            <h6>{props.title}</h6>
            <p>{props.notificationContent}</p>
          </div>
          <p className="duration">{props.duration}</p>
        </div>
        <div className="notification-card-main-body">
          <div className="d-flex justify-content-end">
            <span onClick={props.onHandelDelete} className="delete">
              delete
            </span>
            <span onClick={props.onHandelResend} className="resend">
              resend
            </span>
          </div>
        </div>
      </div>
    </StyledCard>
  )
}

const StyledCard = styled.div`
  background: white;
  padding: 16px;

  :first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  :last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .date-time {
    font-size: 14px;
    font-weight: 600;
    color: #c67d7b;
  }
  .card-top {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    .title {
      h6 {
        font-weight: 500;
        color: ##4a4a4a;
        margin-bottom: 8px;
        font-size: 18px;
        padding-right: 10px;
      }
      p {
        color: #808080;
        font-size: 14px;
      }
    }
    .duration {
      font-size: 14px;
      color: #808080;
    }
  }
  .delete {
    font-size: 14px;
    color: #c67d7b;
    cursor: pointer;
  }
  .resend {
    margin-left: 24px;
    font-size: 14px;
    color: #c67d7b;
    cursor: pointer;
  }
`
