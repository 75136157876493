import React from "react"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Col,
  Row
} from "reactstrap"

import { Link } from "react-router-dom"

import Images from "../../utils/Images"

const NotFound = () => {
  return (
    <div>
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="6" md="8" sm="">
              <Form action="" className="form" method="">
                <Card
                  className="card-login"
                  style={{ marginTop: "8rem", boxShadow: "none" }}
                >
                  <CardHeader>
                    <CardHeader>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "0px"
                        }}
                      >
                        <img
                          style={{ alignSelf: "center" }}
                          src={Images.cardLogo}
                          alt="Logo"
                        />
                      </div>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <br />
                    <FormGroup>
                      <div style={{ marginTop: "30%" }}>
                        <h1
                          style={{
                            textAlign: "center",
                            fontWeight: "Bold",
                            fontFamily: "khula",
                            marginTop: "40%"
                          }}
                        >
                          Lost your way?
                        </h1>

                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            fontFamily: "khula"
                          }}
                        >
                          Sorry, we can't find that page. You'll find loads to
                          explore on the home page.
                        </p>
                      </div>

                      <Link
                        to="/auth/login"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="px-md-5 text-capitalize rounded-pill"
                          style={{
                            backgroundColor: "#F01716",
                            color: "white",
                            height: "54px",
                            fontSize: "16px",
                            alignSelf: "center"
                          }}
                        >
                          Login
                        </Button>
                      </Link>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default NotFound
