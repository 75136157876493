import React from "react"
import Images from "../../utils/Images"
import './style.css'
import { sliceIntoChunks } from "../../utils/functions"

const PAGE_SIZE = 10;

const Pagination = props => {
  const { totalPages, pageNumber, onPressPage } = props

  const getPageNumber = (count) => {
    if (count) {
      let pagesCount = (Number(count) / PAGE_SIZE).toFixed(0)
      pagesCount = pagesCount % 1 === 0 ? pagesCount : pagesCount + 1
      return Number(pagesCount);
    }
    return 0
  }

  return (
    <div className="main-style">
      {(pageNumber > 1) ?
        <div
          className={`left-style mx-4 pointer ${!(pageNumber > 1) ? 'pointer-none' : 'active'}`}
          onClick={() => onPressPage(pageNumber - 1)}
        >
          <img alt="" style={{ height: 16 }} src={!(pageNumber > 1) ? Images.left : Images.left1} />
        </div>
        : <></>}

      {sliceIntoChunks(getPageNumber(totalPages) + 1, pageNumber).map(num => (
        <div
          className={`center-style mx-md-2 mx-sm-1 pointer ${pageNumber === num ? 'active pointer-none' : ''}`}
          onClick={() => onPressPage(num)}
        >
          <span role="contentinfo">{num}</span>
        </div>
      ))}
      {!(pageNumber >= getPageNumber(totalPages)) ?
        <div
          className={`right-style mx-4 pointer ${pageNumber >= getPageNumber(totalPages) ? 'pointer-none' : 'active'}`}
          onClick={() => onPressPage(pageNumber + 1)}
        >
          <img alt="" style={{ height: 16 }} src={pageNumber >= getPageNumber(totalPages) ? Images.right : Images.right1} />
        </div>
        : <></>}
    </div>
  )
}

export default Pagination
