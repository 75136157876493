import React, { useState, useEffect } from "react"
import {
  Button,
  Col,
  Row,
  ButtonGroup
} from "reactstrap"
import NavBar from "../../components/Navbar"
import "./style.css"
import SearchInput from "../../components/Search"
import CustomTable from "../../components/CustomTable"
import { connect } from "react-redux"
import { getUserRequest, deleteUserRequest, baneUserRequest } from "../../screenRedux/usersRedux"
import Pagination from "../../components/Pagination"
import Loader from "react-js-loader";

const Users = (props) => {
  const { requesting, users } = props
  const [toggle, setToggle] = useState('all-users')
  const [pageNumber, setPageNumber] = useState(1)
  const [feedsState, setFeedsState] = useState([])
  const [value, setValue] = useState('')

  useEffect(() => {
    const data = { page: Number(pageNumber), type: toggle, search: false }
    props.getUserRequest(data)
  }, [pageNumber, toggle])

  useEffect(() => {
    users && users?.results?.length && setFeedsState(users?.results)
  }, [users])

  const heading = [
    "Name",
    "Email",
    "Location",
    "Gender",
    "Type",
    "Actions",
  ]

  const selectTabs = (val) => {
    setValue('')
    setToggle(val)
    setPageNumber(1)
    const data = { page: Number(pageNumber), type: val, search: false }
    props.getUserRequest(data)
  }

  const selectPagination = (val) => {
    setPageNumber(val)
    const data = { page: val, type: toggle, search: false }
    props.getUserRequest(data)
  }

  const onChange = (e) => {
    setValue(e.target.value)
    const data = { page: Number(pageNumber), type: toggle, search: e.target.value }
    props.getUserRequest(data)
  }

  return (
    <>
      <NavBar />
      <div className="content">
        <div className="contentWraper">
          <div className="container">
            <Row>
              <Col>
                <h4 className="user-heading">Users</h4>
              </Col>
            </Row>
            <Row>
              <Col md="6" lg="7" xl="4">
                <ButtonGroup>
                  <Button
                    onClick={() => selectTabs('all-users')}
                    className={toggle === 'all-users' ? "toggle-buttons" : "top-button"}
                  >
                    All users
                  </Button>
                  <Button
                    onClick={() => selectTabs("parent")}
                    className={toggle === "parent" ? "toggle-buttons" : "top-button"}
                  >
                    Parents
                  </Button>
                  <Button
                    onClick={() => selectTabs("professional")}
                    className={toggle === "professional" ? "toggle-buttons" : "top-button"}
                  >
                    Professionals
                  </Button>
                </ButtonGroup>
              </Col>
              <Col md="6" lg="5" xl="8" className="mt-4 mt-md-0">
                <SearchInput
                  onChange={onChange}
                  placeHolder="Search"
                  value={value}
                />
              </Col>
            </Row>

            <Row>
              {requesting ?
                (<div className="loader-style">
                  <Loader type="spinner-cub" bgColor={"#C67D7B"} color={'#C67D7B'} size={100} />
                </div>) : !requesting && users && users?.results.length === 0 ? <Col>
                  <div className="data-not-found">No data found</div>
                </Col> :
                  <Col>
                    <CustomTable
                      users={users}
                      heading={heading}
                      deleteUser={props.deleteUserRequest}
                      baneUser={props.baneUserRequest}
                      feedsState={feedsState}
                      setFeedsState={setFeedsState}
                      getUsers={props.getUserRequest}
                      toggle={toggle}
                      pageNumber={pageNumber}
                    />
                    <Pagination
                      totalPages={users?.count}
                      pageNumber={pageNumber}
                      onPressPage={selectPagination}
                    />
                  </Col>
              }
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  requesting: state.usersReducer.requesting,
  users: state.usersReducer.users
})

const mapDispatchToProps = (dispatch) => ({
  getUserRequest: (data) => dispatch(getUserRequest(data)),
  deleteUserRequest: (data) => dispatch(deleteUserRequest(data)),
  baneUserRequest: (data) => dispatch(baneUserRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
