const images = {
  logoImage: require("../../src/assets/images/closedEye.png"),
  sortupdownIcon: require("../../src/assets/images/sortUpDown.png"),
  deleteIcon: require("../../src/assets/images/delete.png"),
  blockIcon: require("../../src/assets/images/block.png"),
  profileImg: require("../../src/assets/images/profile.png"),
  dotIcon: require("../../src/assets/images/dotIcon.png"),
  searchIcon: require("../../src/assets/images/search.png"),
  left: require('../../src/assets/images/left.png'),
  right: require('../../src/assets/images/right.png'),
  left1: require('../../src/assets/images/left1.png'),
  right1: require('../../src/assets/images/right1.png'),
  sendIcon: require("../../src/assets/images/send.png")

}

export default images
