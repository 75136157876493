import { all } from "redux-saga/effects"

//Saga
import loginRedux from "../screenRedux/loginRedux"
import notificationsRedux from "../screenRedux/notificationsRedux"
import usersRedux from "../screenRedux/usersRedux"
import feedbackRedux from "../screenRedux/feedbackRedux"
import historyRedux from "../screenRedux/historyRedux"

export function* mainSaga() {
  yield all([
    loginRedux,
    usersRedux,
    notificationsRedux,
    feedbackRedux,
    historyRedux
  ])
}
