import { all, call, put, takeLatest } from "redux-saga/effects"

// config
import { BASE_URL } from "../config/app"

// utils
import XHR from "../utils/XHR"

import toast, { Toaster } from "react-hot-toast"

// type
export const GET_HISTORY_REQUEST = "eddifi/HISTORY/GET_HISTORY_REQUEST"
export const GET_HISTORY_SUCCESS = "eddifi/HISTORY/GET_HISTORY_SUCCESS"
export const GET_HISTORY_FAILURE = "eddifi/HISTORY/GET_HISTORY_FAILURE"
export const CANCEL_SUBSCRIPTION_REQUEST =
  "eddifi/HISTORY/CANCEL_SUBSCRIPTION_REQUEST"
export const CANCEL_SUBSCRIPTION_SUCCESS =
  "eddifi/HISTORY/CANCEL_SUBSCRIPTION_SUCCESS"
export const CANCEL_SUBSCRIPTION_FAILURE =
  "eddifi/HISTORY/CANCEL_SUBSCRIPTION_FAILURE"
export const REFUND_REQUEST = "eddifi/HISTORY/REFUND_REQUEST"
export const REFUND_SUCCESS = "eddifi/HISTORY/REFUND_SUCCESS"
export const REFUND_FAILURE = "eddifi/HISTORY/REFUND_FAILURE"

// action
export const getHistoryRequest = data => ({
  type: GET_HISTORY_REQUEST,
  data
})

export const getHistorySuccess = data => ({
  type: GET_HISTORY_SUCCESS,
  data
})

export const getHistoryFailure = () => ({
  type: GET_HISTORY_FAILURE
})

export const cancelSubscriptionRequest = data => ({
  type: CANCEL_SUBSCRIPTION_REQUEST,
  data
})

export const cancelSubscriptionSuccess = data => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  data
})

export const cancelSubscriptionFailure = data => ({
  type: CANCEL_SUBSCRIPTION_FAILURE,
  data
})

export const refundPaymentRequest = data => ({
  type: REFUND_REQUEST,
  data
})

export const refundPaymentSuccess = data => ({
  type: REFUND_SUCCESS,
  data
})

export const refundPaymentFailure = data => ({
  type: REFUND_FAILURE,
  data
})

// reducer

const initialState = {
  requesting: false,
  historyData: false,
  cancelSuccess: false,
  refundSuccess: false
}

export const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        requesting: false,
        historyData: action.data
      }

    case GET_HISTORY_FAILURE:
      return {
        ...state,
        requesting: false
      }

    case CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        requesting: false,
        cancelSuccess: action.data
      }

    case CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        requesting: false
      }

    case REFUND_REQUEST:
      return {
        ...state,
        requesting: true
      }

    case REFUND_SUCCESS:
      return {
        ...state,
        requesting: false,
        refundSuccess: action.data
      }

    case REFUND_FAILURE:
      return {
        ...state,
        requesting: false
      }

    default:
      return state
  }
}

// sagas

async function getUsersHistoryAPI(data) {
  let URL = `${BASE_URL}api/v1/create_subscription_data/?page=${data.page}&email=${data.search}`

  const token = localStorage.getItem("accessToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getUsersHistory({ data }) {
  try {
    const response = yield call(getUsersHistoryAPI, data)
    yield put(getHistorySuccess(response.data))
  } catch (e) {
    yield put(getHistoryFailure())
  }
}

async function cancelSubscriptionAPI(data) {
  const URL = `${BASE_URL}api/v1/cancel-subscription/`
  const token = localStorage.getItem("accessToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* cancelSubscription({ data }) {
  try {
    const response = yield call(cancelSubscriptionAPI, data)

    response?.data?.message && toast.success(`${response?.data?.message}`)
  } catch (e) {
    console.log("e", e)
  }
}

async function paymentRefundAPI(data) {
  const URL = `${BASE_URL}api/v1/payment/create_refund/`
  const token = localStorage.getItem("accessToken")
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* paymentRefund({ data }) {
  try {
    const response = yield call(paymentRefundAPI, data)
    response?.data?.message && toast.success(`${response?.data?.message}`)
  } catch (e) {
    console.log("e", e)
  }
}

export default all([
  takeLatest(GET_HISTORY_REQUEST, getUsersHistory),
  takeLatest(CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription),
  takeLatest(REFUND_REQUEST, paymentRefund)
])
