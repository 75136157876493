import React, { useState } from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Container,
  Row,
  Col
} from "reactstrap"
import "./style.css"
import { NavLink } from "react-router-dom"
import { Images } from "../../utils"
import { useHistory } from "react-router-dom"

function NavBar(args) {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const [logoutToggle, setlogoutToggle] = useState(false)

  const logoutFunction = () => {
    localStorage.clear()
    history.push("/auth/login")
  }

  return (
    <div>
      <Navbar {...args} className="navbar">
        <NavbarToggler onClick={toggle} />
        <Container>
          <Row>
            <Col>
              <Collapse isOpen={isOpen} navbar>
                <div className="main-nav-list">
                  <Nav className="" navbar>
                    <NavItem>
                      <NavLink
                        className="nav-link"
                        activeClassName="activeNavLink"
                        to="/admin/users"
                      >
                        {" "}
                        Users{" "}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="nav-link"
                        activeClassName="activeNavLink"
                        to="/admin/feedback"
                      >
                        Feedback
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        activeClassName="activeNavLink"
                        className="nav-link"
                        to="/admin/notifications"
                      >
                        Notifications
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        activeClassName="activeNavLink"
                        className="nav-link"
                        to="/admin/analytics"
                      >
                        Analytics
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        activeClassName="activeNavLink"
                        className="nav-link"
                        to="/admin/history"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Payment History
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div className="profile-section">
                    <img src={Images.profileImg} height={24} width={24} />
                    <div>
                      <p className="m-0 profile-name">DLM</p>
                      <span className="profile-status">Admin</span>
                    </div>
                    <div className="dot-main">
                      <img
                        onClick={() => setlogoutToggle(!logoutToggle)}
                        className="dot-icon"
                        src={Images.dotIcon}
                      />
                      {logoutToggle ? (
                        <div className="logout-tab" onClick={logoutFunction}>
                          <p className="logout-button">Logout</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Collapse>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </div>
  )
}

export default NavBar
